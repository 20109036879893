exports.components = {
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-about-us-tsx": () => import("./../../../src/templates/about-us.tsx" /* webpackChunkName: "component---src-templates-about-us-tsx" */),
  "component---src-templates-careers-tsx": () => import("./../../../src/templates/careers.tsx" /* webpackChunkName: "component---src-templates-careers-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-contact-us-tsx": () => import("./../../../src/templates/contact-us.tsx" /* webpackChunkName: "component---src-templates-contact-us-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-our-work-tsx": () => import("./../../../src/templates/our-work.tsx" /* webpackChunkName: "component---src-templates-our-work-tsx" */),
  "component---src-templates-service-detail-tsx": () => import("./../../../src/templates/service-detail.tsx" /* webpackChunkName: "component---src-templates-service-detail-tsx" */),
  "component---src-templates-services-tsx": () => import("./../../../src/templates/services.tsx" /* webpackChunkName: "component---src-templates-services-tsx" */)
}

